<template>
  <div class="data-monitor-production">
    <Header />
    <div class="title">核心产品发货追踪表</div>
    <div class="header">
      <div class="col col_1">县名</div>
      <div class="col col_2 flex-1">今年累计发货</div>
      <div class="col col_3 flex-1">去年累计发货</div>
      <div class="col col_4 flex-1">今年当月发货</div>
      <div class="col col_5 flex-1">去年下月</div>
    </div>
    <div class="item" v-for="item in list" :key="item.name">
      <div class="col col_1">{{item.name}}</div>
      <div class="col col_2 flex-1">{{item.thisYearTotal}}</div>
      <div class="col col_3 flex-1">{{item.lastYearTotal}}</div>
      <div class="col col_4 flex-1">{{item.thisMonthTotal}}</div>
      <div class="col col_5 flex-1">{{item.lastMonthTotal}}</div>
    </div>
  </div>
</template>

<script>
import Header from '../../../components/header';
export default {
  components: {
    Header,
  },
  data() {
    return {
      list: [
        {
          name: '乌鲁木齐',
          thisYearTotal: 54654564,
          lastYearTotal: 54654564,
          thisMonthTotal: 54654564,
          lastMonthTotal: 54654564,
        },
        {
          name: '河北',
          thisYearTotal: 54654564,
          lastYearTotal: 54654564,
          thisMonthTotal: 54654564,
          lastMonthTotal: 54654564,
        },
        {
          name: '浙江',
          thisYearTotal: 54654564,
          lastYearTotal: 54654564,
          thisMonthTotal: 54654564,
          lastMonthTotal: 54654564,
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.data-monitor-production {
  min-height: 100vh;
  background-color: #FAFBFC;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #2C2C2C;
  .flex-1 {
    flex: 1;
  }
  .title {
    padding: 12px 24px;
    text-align: left;
  }
  .header {
    display: flex;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 6px;
    height: 37px;
  }
  .col_1 {
    width: 40px;
  }
  .item {
    display: flex;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 1px;
    min-height: 37px;
  }
}
</style>